import { Component, OnInit } from '@angular/core';

import { FieldType } from '@ngx-formly/core';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';

import { TaFormlyFieldConfig } from '../ta-formly-types';

import { get } from 'lodash-es';

import * as dayjs from 'dayjs';

@Component({
    selector: 'app-ta-time',
    templateUrl: './ta-time.component.html',
    styleUrls: ['./ta-time.component.scss']
})

export class TaTimeComponent extends FieldType<TaFormlyFieldConfig> implements OnInit {

    public time: Date;

    constructor() {
        super();
    }

    ngOnInit() {
        const value = get(this.model, this.key, '');
        if (value) {
            this.time = dayjs(value).toDate();
        }
    }

    public timeChanged(value: any) {
        if (dayjs(value).isValid()) {
            const formattedTime = dayjs(value).toISOString();
            this.formControl.setValue(formattedTime);
        }
    }

}
