import { AfterViewInit, ElementRef, OnDestroy } from '@angular/core';
import { Directive } from '@angular/core';

import { DatatableComponent } from '@swimlane/ngx-datatable';

import { debounceTime, Observable, Subscription } from 'rxjs';

class ResizeObservable extends Observable<ResizeObserverEntry[]> {
    constructor(elem: HTMLElement) {
        super(subscriber => {
            const ro = new ResizeObserver(entries => {
                subscriber.next(entries);
            });

            // Observe one or multiple elements
            ro.observe(elem);

            return function unsubscribe() {
                ro.unobserve(elem);
                ro.disconnect();
            };
        });
    }
}

@Directive({
    selector: '[appNgxTableResize]'
})
export class NgxTableResizeDirective implements AfterViewInit, OnDestroy {

    private resizeObserverSubscription: Subscription;

    constructor(private table: DatatableComponent, private elementRef: ElementRef<HTMLElement>,) { }

    ngAfterViewInit(): void {
        this.resizeObserverSubscription =
            new ResizeObservable(this.elementRef.nativeElement).pipe(debounceTime(250)).subscribe(() => this.table.recalculate());
    }

    ngOnDestroy(): void {
        if (!this.resizeObserverSubscription.closed) {
            this.resizeObserverSubscription.unsubscribe();
        }
    }

}


