import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { FormlyModule } from '@ngx-formly/core';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';

import { DirectivesModule } from '@directives/directives.module';

import { TaDateComponent } from './ta-date.component';

import { RequiredValidator } from '../ta-validators';

@NgModule({
    declarations: [TaDateComponent],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        BsDatepickerModule,
        DirectivesModule,
        FormlyModule.forChild({
            types: [
                {
                    name: 'ta-date',
                    component: TaDateComponent,
                    defaultOptions: {
                        validators: {
                            required: RequiredValidator,
                        }
                    }
                }
            ]
        })
    ]
})
export class TaFormlyDateModule { }
