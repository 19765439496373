import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule, Routes } from '@angular/router';

import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { AuthLayoutComponent } from './layouts/auth-layout/auth-layout.component';

import { DashboardGuard } from '@guards/dashboard.guard';
import { LoginGuard } from '@guards/login.guard';
import { ServerStatusGuard } from '@guards/server-status.guard';

import { ClientsResolver } from '@resolvers/clients.resolver';

const routes: Routes = [
    {
        path: '',
        redirectTo: 'login',
        pathMatch: 'full'
    },
    {
        path: '',
        component: AdminLayoutComponent,
        canActivate: [DashboardGuard],
        resolve: { clients: ClientsResolver },
        children: [
            {
                path: 'dashboard',
                loadChildren: () => import('./pages/dashboard/dashboard.module').then(m => m.DashboardModule),
            },
            {
                path: 'clients',
                loadChildren: () => import('./pages/clients/clients.module').then(m => m.ClientsModule),
            },
            {
                path: 'templates',
                loadChildren: () => import('./pages/templates/templates.module').then(m => m.TemplatesModule),
            },
            {
                path: 'reports',
                loadChildren: () => import('./pages/reports/reports.module').then(m => m.ReportsModule),
            },
            {
                path: 'settings',
                loadChildren: () => import('./pages/settings/settings.module').then(m => m.SettingsModule),
            },
            {
                path: 'users',
                loadChildren: () => import('./pages/users/users.module').then(m => m.UsersModule),
            },
        ]
    },
    {
        path: '',
        component: AuthLayoutComponent,
        children: [
            {
                path: 'login',
                canActivate: [LoginGuard],
                loadChildren: () => import('./pages/login/login.module').then(m => m.LoginModule),
            },
            {
                path: 'server-status',
                canActivate: [ServerStatusGuard],
                loadChildren: () => import('./pages/server-status/server-status.module').then(m => m.ServerStatusModule),
            },
        ]
    },
    {
        path: '**',
        redirectTo: 'dashboard'
    }
];

@NgModule({
    imports: [
        CommonModule,
        BrowserModule,
        RouterModule.forRoot(routes, {
            useHash: false
        })
    ],
    exports: [RouterModule]
})
export class AppRoutingModule { }
