import { DOCUMENT } from '@angular/common';
import { Component, inject, Inject, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, Event, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';

import { Store } from '@ngxs/store';

import { Observable } from 'rxjs';

import { ApiService, AuthenticationService, ConfigService, NotificationService, StorageService, UserService } from '@services';

import { ResetAssetTemplatesState, ResetAuditTemplatesState, ResetClientSelectionState, ResetClientsState, ResetReportsState, ResetUsersState, SelectClient, UpdateClients } from '@state-management/actions';
import { ClientSelectionState } from '@state-management/states';

import { Client } from '@models';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss']
})

export class NavbarComponent implements OnInit {

    public currentClient$: Observable<string> = inject(Store).select(ClientSelectionState.getCurrentClient);
    public clients$: Observable<Client[]> = inject(Store).select(ClientSelectionState.getClients);

    public clientsFormControl: FormControl = new FormControl('');
    public sidenavOpen = true;

    constructor(
        @Inject(DOCUMENT) private document: Document,
        private activatedRoute: ActivatedRoute,
        private apiService: ApiService,
        private authenticationService: AuthenticationService,
        private configService: ConfigService,
        private notificationService: NotificationService,
        private router: Router,
        public storageService: StorageService,
        private store: Store,
        public userService: UserService
    ) {
        this.router.events.subscribe((event: Event) => {
            if (event instanceof NavigationStart) {
                // Show loading indicator

            }
            if (event instanceof NavigationEnd) {
                // Hide loading indicator

                if (window.innerWidth < 1200) {
                    document.body.classList.remove('g-sidenav-pinned');
                    document.body.classList.add('g-sidenav-hidden');
                    this.sidenavOpen = false;
                }
            }

            if (event instanceof NavigationError) {
                // Hide loading indicator

                // Present error to user
                console.log(event.error);
            }
        });

    }

    ngOnInit() {
        const clients: Client[] = this.activatedRoute.snapshot.data.clients;
        this.store.dispatch(new UpdateClients(clients));
    }

    public async onClientChanges(value: string) {
        if (value) {
            this.store.dispatch(new SelectClient(value));
        }
    }

    public async logout() {
        try {
            await this.authenticationService.logout();
            this.storageService.removeFromStorage(this.configService.userSessionKey);
            this.userService.clearUserData();
            this.router.navigate(['login']);
            this.store.dispatch([ResetClientsState, ResetAuditTemplatesState, ResetAssetTemplatesState, ResetReportsState, ResetUsersState, ResetClientSelectionState]);
        } catch (error) {
            console.log('NavbarComponent: logout(): error=', error);
            this.notificationService.showNotification('danger', 'ni ni-user-run', 'Error Signing Out', 'Please try again. If problem persists, please contact administrator for help.');
        }
    }

    public openSearch() {
        this.document.body.classList.add('g-navbar-search-showing');
        setTimeout(() => {
            this.document.body.classList.remove('g-navbar-search-showing');
            this.document.body.classList.add('g-navbar-search-show');
        }, 150);
        setTimeout(() => {
            this.document.body.classList.add('g-navbar-search-shown');
        }, 300);
    }

    public closeSearch() {
        this.document.body.classList.remove('g-navbar-search-shown');
        setTimeout(() => {
            this.document.body.classList.remove('g-navbar-search-show');
            this.document.body.classList.add('g-navbar-search-hiding');
        }, 150);
        setTimeout(() => {
            this.document.body.classList.remove('g-navbar-search-hiding');
            this.document.body.classList.add('g-navbar-search-hidden');
        }, 300);
        setTimeout(() => {
            this.document.body.classList.remove('g-navbar-search-hidden');
        }, 500);
    }

    public openSidebar() {
        if (this.document.body.classList.contains('g-sidenav-pinned')) {
            this.document.body.classList.remove('g-sidenav-pinned');
            this.document.body.classList.add('g-sidenav-hidden');
            this.sidenavOpen = false;
        } else {
            this.document.body.classList.add('g-sidenav-pinned');
            this.document.body.classList.remove('g-sidenav-hidden');
            this.sidenavOpen = true;
        }
    }

    public toggleSidenav() {
        if (this.document.body.classList.contains('g-sidenav-pinned')) {
            this.document.body.classList.remove('g-sidenav-pinned');
            this.document.body.classList.add('g-sidenav-hidden');
            this.sidenavOpen = false;
        } else {
            this.document.body.classList.add('g-sidenav-pinned');
            this.document.body.classList.remove('g-sidenav-hidden');
            this.sidenavOpen = true;
        }
    }
}
