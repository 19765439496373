import { provideHttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';

import { FormlyBootstrapModule } from '@ngx-formly/bootstrap';
import { FormlyModule } from '@ngx-formly/core';
import { NgxsModule } from '@ngxs/store';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BsDropdownConfig, BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ModalModule } from 'ngx-bootstrap/modal';
import { MonacoEditorModule } from 'ngx-monaco-editor-v2';
import { ToastrModule } from 'ngx-toastr';

import { NetworkInterceptor } from '@interceptors';

import { environment } from '@environments/environment';

import { TaFormlyModule } from './shared/formly/ta-formly.module';

import { LoaderModule } from '@components/loader/loader.module';
import { NavbarModule } from '@components/navbar/navbar.module';
import { SidebarModule } from '@components/sidebar/sidebar.module';
import { GeneralModalModule } from '@shared-modals/general-modal/general-modal.module';
import { ImageViewerModalModule } from '@shared-modals/image-viewer-modal/image-viewer-modal.module';

import { AppComponent } from './app.component';
import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { AuthLayoutComponent } from './layouts/auth-layout/auth-layout.component';

import { ClientSelectionState } from '@state-management/states';

import { AppRoutingModule } from './app-routing.module';

@NgModule({
    declarations: [
        AppComponent,
        AdminLayoutComponent,
        AuthLayoutComponent,
    ],
    imports: [
        BrowserAnimationsModule,
        FormsModule,
        RouterModule,
        LoaderModule,
        NavbarModule,
        SidebarModule,
        GeneralModalModule,
        ImageViewerModalModule,
        FormlyBootstrapModule,
        TaFormlyModule,
        BsDatepickerModule.forRoot(),
        BsDropdownModule.forRoot(),
        MonacoEditorModule.forRoot(),
        AppRoutingModule,
        ToastrModule.forRoot({
            timeOut: 4000,
            closeButton: true,
            enableHtml: true,
            tapToDismiss: false,
            titleClass: 'alert-title',
            positionClass: 'toast-top-center',
        }),
        NgxsModule.forRoot([ClientSelectionState], { developmentMode: !environment.production }),
        CollapseModule.forRoot(),
        ModalModule.forRoot(),
        BrowserModule,
        AppRoutingModule,
        FormlyModule.forRoot({
            validationMessages: [
                { name: 'required', message: 'This field is required' },
            ]
        }),
    ],
    providers: [
        provideHttpClient(),
        {
            provide: HTTP_INTERCEPTORS,
            useClass: NetworkInterceptor,
            multi: true
        },
        // isAnimated set to false in ngx bootstrap dropdown due to bug with ngIf
        { provide: BsDropdownConfig, useValue: { isAnimated: false, autoClose: true } }
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
