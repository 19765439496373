import { Component, EventEmitter, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

import { NetworkLoaderService } from '@services';

import { ModalRequest, ModalResponse } from '@models';

@Component({
    selector: 'app-general-modal',
    templateUrl: './general-modal.component.html',
    styleUrls: ['./general-modal.component.scss']
})
export class GeneralModalComponent implements OnInit {

    public modalHeader: string;
    public modalContent: string;
    public successText = 'Yes';
    public cancelText = 'Cancel';
    public successButtonColor = 'btn-tablue';
    public cancelButtonColor = 'text-danger';

    public modalRequest: ModalRequest;
    private response: EventEmitter<ModalResponse> = new EventEmitter();

    constructor(
        private modalRef: BsModalRef,
        public networkLoaderService: NetworkLoaderService
    ) { }

    ngOnInit(): void {
    }

    public async onSubmit(): Promise<void> {
        const response: ModalResponse = {
            type: this.modalRequest.type,
            data: null
        };

        if (this.modalRequest.func) {
            try {
                const data = await this.modalRequest.func();
                response.data = data;
                this.modalRef.hide();
            } catch (e) {
                console.log(`GeneralModalComponent: onSubmit(): ${this.modalRequest.type}: error=`, e);
                response.error = e.error;
            }
            this.response.emit(response);
        } else {
            this.modalRef.hide();
            this.response.emit(response);
        }

    }

    public onCancel(): void {
        const response: ModalResponse = {
            type: 'on-cancel',
            data: null
        };
        this.response.emit(response);
        this.modalRef.hide();
    }

}
