// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host .navbar-client-selector-wrapper {
  z-index: 99;
}
:host .navbar-client-selector-wrapper button.close {
  z-index: 100;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/navbar/navbar.component.scss"],"names":[],"mappings":"AACI;EACI,WAAA;AAAR;AAMQ;EACI,YAAA;AAJZ","sourcesContent":[":host {\n    .navbar-client-selector-wrapper {\n        z-index: 99;\n\n        .navbar-client-selector {\n            // width: 290px;\n        }\n\n        button.close {\n            z-index: 100;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
