import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { FormlyModule } from '@ngx-formly/core';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';

import { DirectivesModule } from '@directives/directives.module';

import { TaDateTimePickerComponent } from './ta-datetimepicker.component';

import { RequiredValidator } from '../ta-validators';

@NgModule({
    declarations: [TaDateTimePickerComponent],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        BsDatepickerModule,
        DirectivesModule,
        FormlyModule.forChild({
            types: [
                {
                    name: 'ta-datetimepicker',
                    component: TaDateTimePickerComponent,
                    defaultOptions: {
                        validators: {
                            required: RequiredValidator,
                        }
                    }
                }
            ]
        })
    ]
})
export class TaFormlyDateTimePickerModule { }
