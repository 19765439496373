import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { FormlyModule } from '@ngx-formly/core';

import { DirectivesModule } from '@directives/directives.module';

import { TaTimeComponent } from './ta-time.component';

import { RequiredValidator } from '../ta-validators';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';

@NgModule({
    declarations: [TaTimeComponent],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        TimepickerModule,
        DirectivesModule,
        FormlyModule.forChild({
            types: [
                {
                    name: 'ta-time',
                    component: TaTimeComponent,
                    defaultOptions: {
                        validators: {
                            required: RequiredValidator,
                        }
                    }
                }
            ]
        })
    ]
})
export class TaFormlyTimeModule { }
