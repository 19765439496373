import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ImageViewerModalComponent } from './image-viewer-modal.component';

@NgModule({
    declarations: [
        ImageViewerModalComponent
    ],
    imports: [
        CommonModule
    ]
})
export class ImageViewerModalModule { }
