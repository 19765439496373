import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';

import { ConfigService, NetworkLoaderService } from '@services';

@Component({
    selector: 'app-auth-layout',
    templateUrl: './auth-layout.component.html',
    styleUrls: ['./auth-layout.component.scss']
})
export class AuthLayoutComponent implements OnInit, OnDestroy {
    public date: Date = new Date();
    public isCollapsed = true;

    constructor(
        @Inject(DOCUMENT) private document: Document,
        public configService: ConfigService,
        public networkLoaderService: NetworkLoaderService,
    ) { }

    ngOnInit() {
        const body = this.document.getElementsByTagName('body')[0];
        body.classList.add('bg-default');
        const navbar = this.document.getElementsByClassName('navbar-horizontal')[0];
        navbar.classList.add('navbar-light');
        navbar.classList.add('navbar-transparent');
    }

    ngOnDestroy() {
        const body = this.document.getElementsByTagName('body')[0];
        body.classList.remove('bg-default');
        const navbar = this.document.getElementsByClassName('navbar-horizontal')[0];
        navbar.classList.remove('navbar-light');
        navbar.classList.remove('navbar-transparent');
    }
}
