import { Component } from '@angular/core';

import { FieldType } from '@ngx-formly/core';

import { ApiService } from '@services';

import { TaFormlyFieldConfig } from '../ta-formly-types';

import { Asset } from '@models';

const TAG = 'TaProjectSelectComponent ';

@Component({
    selector: 'app-ta-project-select',
    templateUrl: './ta-project-select.component.html',
    styleUrls: ['./ta-project-select.component.scss']
})
export class TaProjectSelectComponent extends FieldType<TaFormlyFieldConfig> {

    public projects: Asset[] = [];
    public isLoading = true;

    constructor(
        private apiService: ApiService
    ) {
        super();
    }

    ngOnInit() {
        this.getProjects();
    }

    private async getProjects() {
        this.isLoading = true;
        try {
            const data = await this.apiService.queryAssets('active', 'project', this.props.clientId);
            if (data && data.length > 0) {
                this.formControl.setValue(data[0].identifiers.asset_identifier);
                this.projects = data;
            }
        } catch (error) {
            console.log(TAG + 'getProjects: error=', error);
        }
        this.isLoading = false;
    }
}
