import { Component, OnInit } from '@angular/core';

import { FieldType } from '@ngx-formly/core';

import { TaFormlyFieldConfig } from '../ta-formly-types';

import { get } from 'lodash-es';

import * as dayjs from 'dayjs';

@Component({
    selector: 'app-ta-date',
    templateUrl: './ta-date.component.html',
    styleUrls: ['./ta-date.component.scss']
})

export class TaDateComponent extends FieldType<TaFormlyFieldConfig> implements OnInit {

    public date: Date;
    public bsConfigValue = { isAnimated: true, containerClass: 'theme-dark-blue', showClearButton: true, adaptivePosition: true, dateInputFormat: 'DD/MM/YYYY' };

    constructor() {
        super();
    }

    ngOnInit() {
        const value = get(this.model, this.key, '');
        if (value) {
            this.date = dayjs(value).toDate();
        }
    }

    public dateChanged(value: any) {
        const formattedDate = dayjs(value).toISOString();
        this.formControl.setValue(formattedDate);
    }

}
