import { Injectable } from '@angular/core';

import { Action, Selector, State, StateContext } from '@ngxs/store';

import { ResetAssetTemplatesState, UpdateAssetTemplatesActivePage, UpdateAssetTemplatesPageSize, UpdateAssetTemplatesSearchQuery, UpdateAssetTemplatesSort } from '@state-management/actions';

import { SortEntries } from '@data';

import { TableState } from '@models';

export interface AssetTemplatesStateModel extends TableState { }

const ASSET_TEMPLATES_STATE_DEFAULT: AssetTemplatesStateModel = {
    pageSize: SortEntries()[0],
    activePage: 0,
    sort: {
        prop: 'title',
        direction: 'asc',
        name: 'Title',
        default: true
    },
    searchQuery: '',
    filtered: false
};

@State<AssetTemplatesStateModel>({
    name: 'assetTemplates',
    defaults: ASSET_TEMPLATES_STATE_DEFAULT
})

@Injectable()
export class AssetTemplatesState {

    @Selector()
    static getAssetTemplatesState(state: AssetTemplatesStateModel): AssetTemplatesStateModel {
        return state;
    }

    @Action(UpdateAssetTemplatesPageSize)
    public updateAssetTemplatesPageSize(ctx: StateContext<AssetTemplatesStateModel>, action: UpdateAssetTemplatesPageSize) {
        ctx.patchState({
            ...ctx.getState(),
            pageSize: action.pageSize,
            filtered: true
        });
    }

    @Action(UpdateAssetTemplatesSearchQuery)
    public updateAssetTemplatesSearchQuery(ctx: StateContext<AssetTemplatesStateModel>, action: UpdateAssetTemplatesSearchQuery) {
        ctx.patchState({
            ...ctx.getState(),
            searchQuery: action.searchQuery,
            filtered: true
        });
    }

    @Action(UpdateAssetTemplatesActivePage)
    public updateAssetTemplatesActivePage(ctx: StateContext<AssetTemplatesStateModel>, action: UpdateAssetTemplatesActivePage) {
        ctx.patchState({
            ...ctx.getState(),
            activePage: action.activePage,
            filtered: true
        });
    }

    @Action(UpdateAssetTemplatesSort)
    public updateAssetTemplatesSort(ctx: StateContext<AssetTemplatesStateModel>, action: UpdateAssetTemplatesSort) {
        ctx.patchState({
            ...ctx.getState(),
            activePage: 0,
            sort: {
                prop: action.sort.prop,
                direction: action.sort.direction,
                name: action.sort.name,
                default: false
            },
            filtered: true
        });
    }

    @Action(ResetAssetTemplatesState)
    public resetAssetTemplatesState(ctx: StateContext<AssetTemplatesStateModel>, action: ResetAssetTemplatesState) {
        ctx.setState(ASSET_TEMPLATES_STATE_DEFAULT);
        if (action.table) {
            action.table.onColumnSort({ sorts: [{ prop: ASSET_TEMPLATES_STATE_DEFAULT.sort.prop, dir: ASSET_TEMPLATES_STATE_DEFAULT.sort.direction }] });
        }
    }
}