import { DatatableComponent } from '@swimlane/ngx-datatable';

export class UpdateReportsPageSize {
    static readonly type = '[Reports] Update Reports Page Size';
    constructor(public pageSize: number) { }
}

export class UpdateReportsSearchQuery {
    static readonly type = '[Reports] Update Reports Search Query';
    constructor(public searchQuery: string) { }
}

export class UpdateReportsActivePage {
    static readonly type = '[Reports] Update Reports Active Page';
    constructor(public activePage: number) { }
}

export class UpdateReportsSort {
    static readonly type = '[Reports] Update Reports Sort';
    constructor(public sort: { name: string, prop: string, direction: 'asc' | 'desc' }) { }
}

export class ResetReportsState {
    static readonly type = '[Reports] Reset Reports State';
    constructor(public table: DatatableComponent) { }
}
