import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';

import { Observable } from 'rxjs';

import { AuthenticationService } from '@services';

@Injectable({
    providedIn: 'root'
})
export class ServerStatusGuard implements CanActivate {

    constructor(
        private authenticationService: AuthenticationService,
        private router: Router
    ) { }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return this.authenticationService.ping().then(() => {
            const returnUrl: string = route.queryParams.return_url;
            if (returnUrl) {
                this.router.navigate([returnUrl]);
            } else {
                this.router.navigate(['/dashboard']);
            }
            return true;
        }).catch(error => {
            return true;
        });

    }

}
