import { NgModule } from '@angular/core';

import { FormControlFocusDirective } from './form-control-focus.directive';
import { FormControlValidationDirective } from './form-control-validation.directive';
import { FormResetDirective } from './form-reset.directive';
import { NgxTableResizeDirective } from './ngx-table-resize.directive';
import { PasswordToggleDirective } from './password-toggle.directive';

@NgModule({
    declarations: [FormControlFocusDirective, FormControlValidationDirective, NgxTableResizeDirective, FormResetDirective, PasswordToggleDirective],
    imports: [],
    exports: [FormControlFocusDirective, FormControlValidationDirective, NgxTableResizeDirective, FormResetDirective, PasswordToggleDirective]
})
export class DirectivesModule { }
