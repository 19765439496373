import { Directive, HostListener, Input } from '@angular/core';

@Directive({
    selector: '[appFormReset]'
})
export class FormResetDirective {

    @Input() form: HTMLFormElement;

    constructor() { }

    @HostListener('click', ['$event'])
    public onClick(event: MouseEvent) {
        this.form.querySelectorAll('.is-invalid').forEach((n: any) => n.classList.remove('is-invalid'));
    }
}
