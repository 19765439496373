import { Directive, ElementRef, HostListener, Renderer2 } from '@angular/core';

@Directive({
    selector: '[appPasswordToggle]'
})
export class PasswordToggleDirective {

    constructor(
        private el: ElementRef,
        private renderer: Renderer2
    ) { }

    @HostListener('click', ['$event'])
    public onClick(event: MouseEvent) {
        this.togglePasswordType();
    }

    private togglePasswordType() {
        const iconElement = this.el.nativeElement as HTMLElement;
        const inputElement = iconElement.parentElement?.parentElement?.previousSibling as HTMLInputElement;
        if (inputElement) {
            if (!inputElement.disabled) {
                if (iconElement.classList.contains('fa-eye')) {
                    inputElement.type = 'text';
                    this.renderer.removeClass(iconElement, 'fa-eye');
                    this.renderer.addClass(iconElement, 'fa-eye-slash');
                } else if (iconElement.classList.contains('fa-eye-slash')) {
                    inputElement.type = 'password';
                    this.renderer.addClass(iconElement, 'fa-eye');
                    this.renderer.removeClass(iconElement, 'fa-eye-slash');
                }
            }
        }
    }
}
