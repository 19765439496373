import { Component } from '@angular/core';

import { FieldType } from '@ngx-formly/core';

import { TaFormlyFieldConfig } from '../ta-formly-types';

@Component({
    selector: 'app-ta-input',
    templateUrl: './ta-input.component.html',
    styleUrls: ['./ta-input.component.scss']
})
export class TaInputComponent extends FieldType<TaFormlyFieldConfig>  {

    constructor() {
        super();
    }
}
