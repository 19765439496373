import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { ConfigService } from '@services';

import { MENU_ITEMS } from '@data';

const misc: { sidebar_mini_active: boolean } = {
    sidebar_mini_active: true
};

@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
    public menuItems: any[];
    public isCollapsed = true;

    constructor(
        @Inject(DOCUMENT) private document: Document,
        public configService: ConfigService,
        private router: Router
    ) { }

    ngOnInit() {
        this.menuItems = MENU_ITEMS.filter(menuItem => menuItem);
        this.router.events.subscribe(event => {
            this.isCollapsed = true;
        });
    }

    public onMouseEnterSidenav() {
        if (!this.document.body.classList.contains('g-sidenav-pinned')) {
            this.document.body.classList.add('g-sidenav-show');
        }
    }

    public onMouseLeaveSidenav() {
        if (!this.document.body.classList.contains('g-sidenav-pinned')) {
            this.document.body.classList.remove('g-sidenav-show');
        }
    }

    public minimizeSidebar() {
        const sidenavToggler = this.document.getElementsByClassName(
            'sidenav-toggler'
        )[0];
        const body = this.document.getElementsByTagName('body')[0];
        if (body.classList.contains('g-sidenav-pinned')) {
            misc.sidebar_mini_active = true;
        } else {
            misc.sidebar_mini_active = false;
        }
        if (misc.sidebar_mini_active === true) {
            body.classList.remove('g-sidenav-pinned');
            body.classList.add('g-sidenav-hidden');
            sidenavToggler.classList.remove('active');
            misc.sidebar_mini_active = false;
        } else {
            body.classList.add('g-sidenav-pinned');
            body.classList.remove('g-sidenav-hidden');
            sidenavToggler.classList.add('active');
            misc.sidebar_mini_active = true;
        }
    }
}
