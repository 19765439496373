import { Injectable } from '@angular/core';

import { environment } from '@environments/environment';

@Injectable({
    providedIn: 'root'
})

export class ConfigService {

    public clientDashboardUrl: string;
    public restApiUrl: string;
    public userSessionKey = '_taadmin-user-session';

    constructor() {
        this.clientDashboardUrl = environment.clientDashboardUrl;
        this.restApiUrl = environment.restApiUrl;
    }
}
