import { RouteInfo } from '@models';

export const MENU_ITEMS: RouteInfo[] = [
    {
        path: '/dashboard',
        title: 'Dashboard',
        type: 'link',
        icontype: 'fa fa-tachometer-alt text-tablue',
    },
    {
        path: '/clients',
        title: 'Clients',
        type: 'link',
        icontype: 'fas fa-users text-tablue',
    },
    {
        path: '/templates',
        title: 'Templates',
        type: 'sub',
        icontype: 'fas fa-file text-tablue',
        collapse: 'templates',
        isCollapsed: true,
        children: [
            { path: 'assets', title: 'Assets', type: 'link' },
            { path: 'audits', title: 'Audits', type: 'link' },
            { path: 'projects', title: 'Projects', type: 'link' },
            { path: 'users', title: 'Users', type: 'link' }
        ]
    },
    {
        path: '/reports',
        title: 'Reports',
        type: 'sub',
        icontype: 'ni ni-single-copy-04 text-tablue',
        collapse: 'reports',
        isCollapsed: true,
        children: [
            { path: 'standard-reports', title: 'Standard Reports', type: 'link' },
            { path: 'custom-reports', title: 'Custom Reports', type: 'link' },
        ]
    },
    {
        path: '/users',
        title: 'Users',
        type: 'link',
        icontype: 'fas fa-users text-tablue',
    },
    {
        path: '/settings',
        title: 'Settings',
        type: 'link',
        icontype: 'fa fa-cog text-tablue',
    }
];