import { Injectable } from '@angular/core';

import { ConfigService, StorageService } from '@services';

import { Session, UserSession } from '@models';

@Injectable({
    providedIn: 'root'
})

export class UserService {

    private currentUser: {
        email: string;
        first_name: string;
        last_name: string;
    };
    private session: Session;
    private is_superuser: boolean;

    constructor(
        private configService: ConfigService,
        private storageService: StorageService
    ) {
        this.setCurrentUser();
    }

    public setCurrentUser() {
        const userSession = this.storageService.getFromStorage<UserSession>(this.configService.userSessionKey);
        if (userSession) {
            this.currentUser = userSession.contact;
            this.session = userSession.session;
            this.is_superuser = userSession.is_superuser;
        }
    }

    public getCurrentUser() {
        return this.currentUser;
    }

    public getCurrentSession() {
        return this.session;
    }

    public getIsSuperUser() {
        return this.is_superuser;
    }

    public clearUserData() {
        this.currentUser = null;
        this.session = null;
        this.is_superuser = null;
    }
}
