import { Injectable } from '@angular/core';

import { ToastrService } from 'ngx-toastr';

@Injectable({
    providedIn: 'root'
})

export class NotificationService {

    constructor(
        private toastr: ToastrService
    ) { }

    public showNotification(type: 'info' | 'success' | 'danger' | 'warning' | 'default', icon: string, title: string, body?: string) {
        this.toastr.show(
            `<span class="alert-icon ${icon}" data-notify="icon"></span> <div class="alert-text mt-2 mt-md-0"</div> <span class="alert-title" data-notify="title">${title}</span> ${body ? `<span data-notify="message">${body}</span>` : ''}</div>`,
            '',
            {
                toastClass: `ngx-toastr alert alert-dismissible alert-${type} alert-notify`
            }
        );
    }
}
