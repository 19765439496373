import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CollapseModule } from 'ngx-bootstrap/collapse';

import { NgScrollbarModule } from 'ngx-scrollbar';

import { SidebarComponent } from './sidebar.component';


@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        CollapseModule,
        NgScrollbarModule
    ],
    declarations: [
        SidebarComponent
    ],
    exports: [
        SidebarComponent,
    ],
    providers: []
})
export class SidebarModule { }
