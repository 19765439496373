import { ChangeDetectorRef, Component } from '@angular/core';

import { FieldType } from '@ngx-formly/core';

import { ApiService } from '@services';

import { TaFormlyFieldConfig } from '../ta-formly-types';

import { Asset } from '@models';

const TAG = 'TaUserSelectComponent ';

@Component({
    selector: 'app-ta-user-select',
    templateUrl: './ta-user-select.component.html',
    styleUrls: ['./ta-user-select.component.scss']
})
export class TaUserSelectComponent extends FieldType<TaFormlyFieldConfig> {

    public userAssets: Asset[] = [];
    public isLoading = true;

    constructor(
        private apiService: ApiService,
        private changeDetectorRef: ChangeDetectorRef,
    ) {
        super();
    }

    ngOnInit() {
        this.getUserAssets();
    }

    private async getUserAssets() {
        this.isLoading = true;
        try {
            const data = await this.apiService.queryAssets('active', 'user', this.props.clientId);
            if (data && data.length > 0) {
                if (!this.formControl.value) {
                    this.formControl.setValue(this.props.multiple ? [] : data[0].identifiers.asset_identifier);
                }
                this.userAssets = data;
            }
        } catch (error) {
            console.log(TAG + 'getUserAssets: error=', error);
        }
        this.isLoading = false;
        this.changeDetectorRef.detectChanges();
    }
}
