import { FormControl, FormGroup } from '@angular/forms';

import { FormlyFieldConfig } from '@ngx-formly/core';

// Validators
export function RequiredValidator(formControl: FormControl, field: FormlyFieldConfig): boolean {
    // tslint:disable-next-line: no-string-literal
    if (field['ta_validators']?.required) {
        if (field.props.multiple) {
            return formControl.value?.length > 0 ? true : false;
        }
        return formControl.value ? true : false;
    }
    return true;
}