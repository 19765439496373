import { Directive, ElementRef, HostListener, Renderer2 } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
    selector: '[appFormControlValidation]'
})
export class FormControlValidationDirective {

    constructor(
        private el: ElementRef,
        private ngControl: NgControl,
        private renderer: Renderer2
    ) { }

    @HostListener('input', ['$event.target'])
    public onInputChange(target: any) {
        // Used for input element
        if (target.localName === 'input') {
            this.checkAndAddInvalidClass();
            this.checkAndRemoveInvalidClass();
        }
    }

    @HostListener('change', ['$event'])
    public onSelectChange(target: any) {
        // Used for ng-select element
        if (!target.localName) {
            this.checkAndAddInvalidClass();
            this.checkAndRemoveInvalidClass();
        }
    }

    @HostListener('bsValueChange', ['$event'])
    public onSelectChanges(target: any) {
        // Used for bsdatepicker element
        setTimeout(() => {
            if (target) {
                this.checkAndAddInvalidClass();
                this.checkAndRemoveInvalidClass();
            }
        });
    }

    @HostListener('focus', ['$event.target'])
    public onFocus(target: any) {
        if (target.localName === 'input') {
            this.checkAndAddInvalidClass();
        }
    }

    @HostListener('blur', ['$event.target'])
    public onBlur(target: any) {
        if (target.localName === 'input') {
            this.checkAndRemoveInvalidClass();
        }
    }

    private checkAndAddInvalidClass() {
        if (!this.ngControl.control.valid) {
            const element: HTMLInputElement = this.el.nativeElement;
            if (element.localName === 'input') {
                if (!element.classList.contains('is-invalid')) {
                    this.renderer.addClass(element, 'is-invalid');
                    const inputPrependIconElement = element.previousSibling?.firstChild as HTMLInputElement;
                    if (inputPrependIconElement) {
                        if (inputPrependIconElement.classList.contains('input-group-text') && !inputPrependIconElement.classList.contains('is-invalid')) {
                            this.renderer.addClass(inputPrependIconElement, 'is-invalid');
                        }
                    }

                    const inputAppendIconElement = element.nextSibling?.firstChild as HTMLInputElement;
                    if (inputAppendIconElement) {
                        if (inputAppendIconElement.classList.contains('input-group-text') && !inputAppendIconElement.classList.contains('is-invalid')) {
                            this.renderer.addClass(inputAppendIconElement, 'is-invalid');
                        }
                    }
                }
            } else if (element.localName === 'ng-select') {
                const selectElement = element.firstChild as HTMLInputElement;
                if (!selectElement.classList.contains('is-invalid')) {
                    this.renderer.addClass(selectElement, 'is-invalid');
                }
            }
        }
    }

    private checkAndRemoveInvalidClass() {
        if (this.ngControl.control.valid) {
            const element: HTMLInputElement = this.el.nativeElement;
            if (element.localName === 'input') {
                if (element.classList.contains('is-invalid')) {
                    this.renderer.removeClass(element, 'is-invalid');
                    const inputPrependIconElement = element.previousSibling?.firstChild as HTMLInputElement;
                    if (inputPrependIconElement) {
                        if (inputPrependIconElement.classList.contains('input-group-text') && inputPrependIconElement.classList.contains('is-invalid')) {
                            this.renderer.removeClass(inputPrependIconElement, 'is-invalid');
                        }
                    }

                    const inputAppendIconElement = element.nextSibling?.firstChild as HTMLInputElement;
                    if (inputAppendIconElement) {
                        if (inputAppendIconElement.classList.contains('input-group-text') && inputAppendIconElement.classList.contains('is-invalid')) {
                            this.renderer.removeClass(inputAppendIconElement, 'is-invalid');
                        }
                    }
                }
            } else if (element.localName === 'ng-select') {
                const selectElement = element.firstChild as HTMLInputElement;
                if (selectElement.classList.contains('is-invalid')) {
                    this.renderer.removeClass(selectElement, 'is-invalid');
                }
            }
        }
    }

}
