import { DatatableComponent } from '@swimlane/ngx-datatable';

export class UpdateAuditTemplatesPageSize {
    static readonly type = '[Audit Templates] Update Audit Templates Page Size';
    constructor(public pageSize: number) { }
}

export class UpdateAuditTemplatesSearchQuery {
    static readonly type = '[Audit Templates] Update Audit Templates Search Query';
    constructor(public searchQuery: string) { }
}

export class UpdateAuditTemplatesActivePage {
    static readonly type = '[Audit Templates] Update Audit Templates Active Page';
    constructor(public activePage: number) { }
}

export class UpdateAuditTemplatesSort {
    static readonly type = '[Audit Templates] Update Audit Templates Sort';
    constructor(public sort: { name: string, prop: string, direction: 'asc' | 'desc' }) { }
}

export class ResetAuditTemplatesState {
    static readonly type = '[Audit Templates] Reset Audit Templates State';
    constructor(public table: DatatableComponent) { }
}
