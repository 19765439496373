import { Component, HostListener, OnInit } from '@angular/core';

import { NetworkLoaderService } from '@services';

@Component({
    selector: 'app-admin-layout',
    templateUrl: './admin-layout.component.html',
    styleUrls: ['./admin-layout.component.scss']
})

export class AdminLayoutComponent implements OnInit {
    public isMobileResolution: boolean;

    constructor(
        public networkLoaderService: NetworkLoaderService
    ) {
        if (window.innerWidth < 1200) {
            this.isMobileResolution = true;
        } else {
            this.isMobileResolution = false;
        }
    }

    @HostListener('window:resize', ['$event'])
    public isMobile(event) {
        if (window.innerWidth < 1200) {
            this.isMobileResolution = true;
        } else {
            this.isMobileResolution = false;
        }
    }

    ngOnInit() { }
}
