import { Component } from '@angular/core';

import { FieldType } from '@ngx-formly/core';

import { TaFormlyFieldConfig } from '../ta-formly-types';

@Component({
    selector: 'app-ta-toggle',
    templateUrl: './ta-toggle.component.html',
    styleUrls: ['./ta-toggle.component.scss']
})
export class TaToggleComponent extends FieldType<TaFormlyFieldConfig>  {

    constructor() {
        super();
    }
}
