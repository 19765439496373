import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';

import { ApiService } from '@services';

import { Client } from '@models';

@Injectable({
    providedIn: 'root'
})
export class ClientsResolver implements Resolve<Client[]> {

    constructor(
        private apiService: ApiService
    ) { }

    async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<Client[]> {
        return await this.apiService.listClients();
    }
}
