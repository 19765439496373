import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { NetworkLoaderService } from '@services';

import { finalize, Observable } from 'rxjs';

@Injectable()
export class NetworkInterceptor implements HttpInterceptor {

    constructor(private networkLoaderService: NetworkLoaderService) { }

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        this.networkLoaderService.show();
        return next.handle(request).pipe(
            finalize(() => {
                this.networkLoaderService.hide();
            })
        );
    }
}
