import { Injectable } from '@angular/core';

import { Action, Selector, State, StateContext } from '@ngxs/store';

import { ResetReportsState, UpdateReportsActivePage, UpdateReportsPageSize, UpdateReportsSearchQuery, UpdateReportsSort } from '@state-management/actions';

import { SortEntries } from '@data';

import { TableState } from '@models';

export interface ReportsStateModel extends TableState { }

const REPORTS_STATE_DEFAULT: ReportsStateModel = {
    pageSize: SortEntries()[0],
    activePage: 0,
    sort: {
        prop: 'title',
        direction: 'asc',
        name: 'Title',
        default: true
    },
    searchQuery: '',
    filtered: false
};

@State<ReportsStateModel>({
    name: 'reports',
    defaults: REPORTS_STATE_DEFAULT
})

@Injectable()
export class ReportsState {

    @Selector()
    static getReportsState(state: ReportsStateModel): ReportsStateModel {
        return state;
    }

    @Action(UpdateReportsPageSize)
    public updateReportsPageSize(ctx: StateContext<ReportsStateModel>, action: UpdateReportsPageSize) {
        ctx.patchState({
            ...ctx.getState(),
            pageSize: action.pageSize,
            filtered: true
        });
    }

    @Action(UpdateReportsSearchQuery)
    public updateReportsSearchQuery(ctx: StateContext<ReportsStateModel>, action: UpdateReportsSearchQuery) {
        ctx.patchState({
            ...ctx.getState(),
            searchQuery: action.searchQuery,
            filtered: true
        });
    }

    @Action(UpdateReportsActivePage)
    public updateReportsActivePage(ctx: StateContext<ReportsStateModel>, action: UpdateReportsActivePage) {
        ctx.patchState({
            ...ctx.getState(),
            activePage: action.activePage,
            filtered: true
        });
    }

    @Action(UpdateReportsSort)
    public updateReportsSort(ctx: StateContext<ReportsStateModel>, action: UpdateReportsSort) {
        ctx.patchState({
            ...ctx.getState(),
            activePage: 0,
            sort: {
                prop: action.sort.prop,
                direction: action.sort.direction,
                name: action.sort.name,
                default: false
            },
            filtered: true
        });
    }

    @Action(ResetReportsState)
    public resetReportsState(ctx: StateContext<ReportsStateModel>, action: ResetReportsState) {
        ctx.setState(REPORTS_STATE_DEFAULT);
        if (action.table) {
            action.table.onColumnSort({ sorts: [{ prop: REPORTS_STATE_DEFAULT.sort.prop, dir: REPORTS_STATE_DEFAULT.sort.direction }] });
        }
    }
}