import { Injectable } from '@angular/core';

import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { append, insertItem, patch, removeItem, updateItem } from '@ngxs/store/operators';

import { AddClient, DeleteClient, ResetClientSelectionState, SelectClient, UpdateClient, UpdateClients } from '@state-management/actions';

import { Client } from '@models';

export interface ClientSelectionStateModel {
    client_id: string;
    clients: Client[];
}

const CLIENT_SELECTION_STATE_DEFAULT: ClientSelectionStateModel = {
    client_id: 'all',
    clients: []
};

@State<ClientSelectionStateModel>({
    name: 'clientSelection',
    defaults: CLIENT_SELECTION_STATE_DEFAULT
})

@Injectable()
export class ClientSelectionState {

    constructor(
        private store: Store
    ) { }

    @Selector()
    static getCurrentClient(state: ClientSelectionStateModel): string {
        return state.client_id;
    }

    @Selector()
    static getClients(state: ClientSelectionStateModel): Client[] {
        return state.clients;
    }

    @Action(AddClient)
    public addClient(ctx: StateContext<ClientSelectionStateModel>, action: AddClient) {
        ctx.setState(
            patch({
                clients: insertItem(action.client as Client)
            })
        );
    }

    @Action(DeleteClient)
    public deleteClient(ctx: StateContext<ClientSelectionStateModel>, action: DeleteClient) {
        ctx.setState(
            patch({
                clients: removeItem<Client>(client => client.id === action.clientId)
            })
        );
        if (ctx.getState().client_id === action.clientId) {
            this.store.dispatch(new SelectClient('all'));
        }
    }

    @Action(UpdateClient)
    public updateClient(ctx: StateContext<ClientSelectionStateModel>, action: UpdateClient) {
        ctx.setState(
            patch({
                clients: updateItem<Client>(client => client.id === action.client.id, action.client as Client)
            })
        );
    }

    @Action(SelectClient)
    public selectClient(ctx: StateContext<ClientSelectionStateModel>, action: SelectClient) {
        ctx.patchState({
            ...ctx.getState(),
            client_id: action.clientId
        });
    }

    @Action(UpdateClients)
    public updateClients(ctx: StateContext<ClientSelectionStateModel>, action: UpdateClients) {
        ctx.patchState({
            ...ctx.getState(),
            clients: action.clients
        });
    }

    @Action(ResetClientSelectionState)
    public resetClientSelectionState(ctx: StateContext<ClientSelectionStateModel>) {
        ctx.setState(CLIENT_SELECTION_STATE_DEFAULT);
    }
}