import { Component, OnInit } from '@angular/core';

import { BsModalRef } from 'ngx-bootstrap/modal';

import { ModalResponse } from '@models';

@Component({
    selector: 'app-image-viewer-modal',
    templateUrl: './image-viewer-modal.component.html',
    styleUrls: ['./image-viewer-modal.component.scss']
})
export class ImageViewerModalComponent implements OnInit {

    public imageSource: string;
    public imageName?: string;
    public isImageLoading = true;

    constructor(
        private modalRef: BsModalRef,
    ) { }

    ngOnInit(): void {
    }

    public imageLoading() {
        this.isImageLoading = false;
    }

    public onCancel(): void {
        const response: ModalResponse = {
            type: 'on-cancel',
            data: null
        };
        this.modalRef.hide();
    }
}
